<template>
  <section>
      <div class="content-header">
        <h2>Journal articles</h2>
      </div>
      <div class="content-wrapper">
        <ul>
          <li class="pb-3"><a href="https://link.springer.com/article/10.1007/s11092-020-09351-7" target="_blank">Academic resilience: underlying norms and validity of definitions </a></li>
          <li class="pb-3"><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4235534/" target="_blank">Academic resilience in education: the role of achievement goal orientations</a></li>
          <li class="pb-3"><a href="https://www.sciencedirect.com/science/article/pii/S0022440507000131" target="_blank">Academic buoyancy: Towards an understanding of students’ everyday academic resilience</a></li>
          <li class="pb-3"><a href="https://www.sciencedirect.com/science/article/abs/pii/S0747563218303431" target="_blank">Learning analytics messages: Impact of grade, sender, comparative information and message style on student affect and academic resilience</a></li>
          <li class="pb-3"><a href="https://journals.sagepub.com/doi/10.1177/0739986309333020" target="_blank">Academic Resilience Among Undocumented Latino Students</a></li>
          <li class="pb-3"><a href="https://doaj.org/article/c64e2885768f4832ba926b3fc74cb5a7" target="_blank">Effectiveness of Time Management Training on Nursing Students' Academic Achievement and Resilience</a></li>
          <li class="pb-3"><a href="https://journals.sagepub.com/doi/10.1177/0042085916660347" target="_blank">Educational Resilience and Academic Achievement of Immigrant Students from Ghana in an Urban School Environment</a></li>
          <li class="pb-3"><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4661232/" target="_blank">Resilience Building in Students: The Role of Academic Self-Efficacy</a></li>
          <li class="pb-3"><a href="https://journals.sagepub.com/doi/full/10.1177/0165025419880614" target="_blank">Promoting persistence: Peer group influences on students’ re-engagement following academic problems and setbacks </a></li>
          <li class="pb-3"><a href="https://www.tandfonline.com/doi/abs/10.1080/03055698.2014.955741?journalCode=ceds20" target="_blank">Promoting resilience through adversity: increasing positive outcomes for expelled students </a></li>
          <li class="pb-3"><a href="https://pubmed.ncbi.nlm.nih.gov/29128734/" target="_blank">Promoting resilience among nursing students in clinical education </a></li>
          <li class="pb-3"><a href="https://journals.sagepub.com/doi/10.1177/000494410204600104" target="_blank">Motivation and Academic Resilience: Developing a Model for Student Enhancement</a></li>
          <li class="pb-3"><a href="https://journals.sagepub.com/doi/full/10.1177/0165025420924122" target="_blank">“I get knocked down but I get up again”: Integrative frameworks for studying the development of motivational resilience in school</a></li>
          <li class="pb-3"><a href="https://www.tandfonline.com/doi/abs/10.1080/01463373.2020.1779099?journalCode=rcqu20" target="_blank">The role of classroom relationships as sources of academic resilience and hope </a></li>
          <li class="pb-3"><a href="https://psycnet.apa.org/record/2015-05636-001" target="_blank">Enhancing attention and memory during video-recorded lectures</a></li>
          <li class="pb-3"><a href="https://journals.sagepub.com/doi/abs/10.1177/00957984211001195?ai=1gvoi&mi=3ricys&af=R" target="_blank">Black Students’ Perceptions of Campus Climates and the Effect on Academic Resilience</a></li>
          <li class="pb-3"><a href="https://journals.sagepub.com/doi/abs/10.1177/0143034320908001" target="_blank">Chinese adolescents’ belief in a just world and academic resilience: The mediating role of perceived academic competence</a></li>
          <li class="pb-3"><a href="https://www.tandfonline.com/doi/abs/10.1080/17439760.2020.1752785?journalCode=rpos20" target="_blank">VIA character strengths among U.S. college students and their associations with happiness, well-being, resiliency, academic success and psychopathology </a></li>
          <li class="pb-3"><a href="https://journals.sagepub.com/doi/10.1177/0165025419885029" target="_blank">Introduction to the special section: Mindfulness in me and in you—Measurement, development, and implications for adolescents’ emotional and academic resilience</a></li>
          <li class="pb-3"><a href="https://eric.ed.gov/?id=EJ1271022" target="_blank">Interaction between Academic Resilience and Academic Achievement of Teacher Trainees </a></li>
          <li class="pb-3"><a href="https://journals.sagepub.com/doi/abs/10.1177/0031721714553408?journalCode=pdka" target="_blank">Classroom culture promotes academic resiliency </a></li>
          <li class="pb-3"><a href="https://journals.sagepub.com/doi/full/10.1177/0972063420983111" target="_blank">Resilience and Psychological Well-Being of Higher Education Students During COVID-19: The Mediating Role of Perceived Distress</a></li>
        </ul>
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '02',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
